.main {
	padding-top: 0.5rem;
	overflow: scroll;
	height: 55vh;
	width: 100%;
	display: grid;
	justify-content: center;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: white;
		height: 100vh;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #f4b34a6c;
		height: 20px;
		border-radius: 0.5rem;
	}

	.container1 {
		display: grid;
		justify-content: center;
		align-items: baseline;
		row-gap: 10px;
		margin-top: 1rem;
		width: 40vw;
		height: 100vh;

		p {
			p {
				line-height: 20px;
			}
		}

		img {
			margin-top: 1rem !important;
		}

		ol {
			padding-left: 1rem;
			line-height: 20px;
		}

		ul {
			margin-left: 1rem;

			li {
				code {
					background-color: rgb(206, 206, 206);
				}
			}
		}
	}
	.container2 {
		display: grid;
		row-gap: 10px;
		margin-top: 1rem;

		.output {
			background-color: rgb(206, 206, 206);
			padding: 1rem;
			display: grid;
			row-gap: 10px;
		}
	}
}
