.container {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: space-between;
	// align-items: center;
	.left {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 80%;
		height: 100%;
		// padding-top: 2%;
		.loginButton {
			margin-top: 2rem;
			padding: 0.6em 3em;
			font-size: 1.2rem;
			color: white;
			background-color: #f4b34a;
			border: 0px;
			cursor: pointer;
			font-weight: 600;
			border-radius: 0.5em;

			&:hover {
				background-color: rgb(18, 18, 18);
				color: white;
			}
		}
	}
	.right {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// position: relative;
		height: 100%;
		.logo {
			width: 30%;
			// margin-bottom: 2rem;
			margin-top: 3%;
		}
		.stepsCont {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			width: 80%;
			align-items: center;
			margin: 0 auto;
			hr {
				width: 80%;
				height: 1px;
				border: 1px solid #f4b34a;
				margin-top: 5%;
			}
			div {
				margin-top: 5%;
				height: 20px;
				width: 20px;
				border: 2px solid #f4b34a;
				border-radius: 50%;
				background-color: #f4b34a;
			}
		}

		.Step1 {
			display: grid;
			grid-template-columns: 1fr 1fr;
			width: 100%;
			.inputs {
				justify-self: center;
				margin-top: 2rem;
				width: 80%;
			}
		}
		.inputsAd {
			justify-self: center;
			margin-top: 2rem;
			width: 90%;
		}
	}
}
.flexButtons {
	display: flex;
	width: 100%;
	flex-direction: row;
	// position: absolute;
	// bottom: 0;
	// margin: 0 auto;
	justify-content: space-between;
	padding: 1rem 3rem;
	// background-color: rgba(128, 128, 128, 0.409);
	.botton {
		border: 0px;
		background-color: #f4b34a;
		padding: 0.5rem 3rem;
		color: white;
		font-weight: 600;
		font-size: 1rem;
		border-radius: 0.5rem;
		cursor: pointer;

		&:hover {
			background-color: rgb(18, 18, 18);
			color: white;
		}
	}
}
