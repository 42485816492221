/* CSS */

  .card {
    width: 100%;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.393);
    cursor: pointer;
    background-color: transparent !important;
    font-size: 1rem;
    font-weight: 600;
  }
  /* CSS */
 
  
  @media (min-width: 768px) {
    .button82Front {
      font-size: 1rem;
      padding: 10px 34px;
    }
  }
  
  .button82Pushable:hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
  }
  
  .button82Pushable:hover .button82Front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .button82Pushable:active .button82Front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .button82Pushable:hover .button82Shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .button82Pushable:active .button82Shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .button82Pushable:focus:not(:focus-visible) {
    outline: none;
  }
  
  .checkboxWrapper54 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }
  
  .checkboxWrapper54 *,
  .checkboxWrapper54 ::after,
  .checkboxWrapper54 ::before {
    box-sizing: border-box;
  }
  
  /* The switch - the box around the slider */
  .checkboxWrapper54 .switch {
    --width-of-switch: 3.5em;
    --height-of-switch: 2em;
    /* size of sliding icon -- sun and moon */
    --size-of-icon: 1.4em;
    /* it is like a inline-padding of switch */
    --slider-offset: 0.3em;
    position: relative;
    width: var(--width-of-switch);
    height: var(--height-of-switch);
    display: inline-block;
  }
  
  /* The slider */
  .checkboxWrapper54 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgb(218, 215, 215);
    transition: 0.4s;
    border-radius: 30px;
  }
  
  .checkboxWrapper54 .slider:before {
    position: absolute;
    content: "";
    height: var(--size-of-icon, 1.4em);
    width: var(--size-of-icon, 1.4em);
    border-radius: 20px;
    left: var(--slider-offset, 0.3em);
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(40deg, #ff0080, #ff8c00 70%);
    transition: 0.4s;
  }
  
  .checkboxWrapper54 input:checked + .slider {
    background-color: #303136;
  }
  
  .checkboxWrapper54 input:checked + .slider:before {
    left: calc(100% - (var(--size-of-icon, 1.4em) + var(--slider-offset, 0.3em)));
    background: #303136;
    /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
    box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
  }
  input {
    width: 20rem;
    height: 2rem;
    border: 0;
    // background-color: rgb(245, 244, 244);
    border-radius: 0.3rem;
  }
  