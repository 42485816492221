.main {
	padding: 1.5rem 0px;
	height: 100vh;
	position: relative;
	background-color: white;

	main {
		width: 100%;
		height: 100%;
		position: relative;

		.footer {
			width: 100%;
			height: 100px;
			border-top: 1px solid;
			position: absolute;
			bottom: calc(100% - 84%);
			background-color: white;
			padding: 1rem;
			display: flex;
			justify-content: space-between;

			.next {
				padding: 0px 2rem;
				height: 50px;
				font-size: 20px;
				border-radius: 5px;
				border: none;
				outline: none;
				background-color: #e39c2f;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 20px;
				font-weight: 600;
				margin-left: 2rem;
				cursor: pointer;
				position: relative;

				& > .uploadFile {
					position: absolute;
					width: 100%;
					height: 100%;
					border: 1px solid black;
					top: 0;
					left: 0;
					z-index: 1;
					opacity: 0;
					cursor: pointer;
				}

				&:hover {
					color: white;
					background-color: black;
				}
			}

			.prev {
				div {
					rotate: 180deg;
				}
			}
		}

		.title {
			display: flex;
			justify-content: center;
			// padding-left: 14.5rem;

			h3 {
				padding: 0px 2rem 0px 1.5rem;
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 10px;
				// margin-left: -10.5rem;
				span {
					margin-left: 10px;
				}
			}
		}

		.dashes {
			display: flex;
			justify-content: center;
			width: 100%;
			padding-top: 1rem;
			// padding: 1rem 2rem 0px 16.5rem;
			gap: 20px;

			div {
				width: 80px;
				height: 6px;
				background-color: #f4b34a98;
				border-radius: 5px;
				cursor: pointer;
			}
		}

		.body {
			padding-top: 1rem;
			// display: flex;
			// justify-content: center;
			// border: 2px solid green;

			#notes {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				word-break: normal;
				row-gap: 20px;
				height: 56vh;
				overflow: scroll;
				&::-webkit-scrollbar {
					width: 10px;
				}

				&::-webkit-scrollbar-track {
					background-color: white;
					height: 100vh;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #f4b34a6c;
					height: 20px;
					border-radius: 0.5rem;
				}

				img {
					margin-top: 1rem !important;
				}

				ol {
					width: 63%;
					word-break: normal;
					padding-left: 2rem;

					li {
						line-height: 30px;

						& > span {
							font-size: 16px !important;
						}
					}
				}

				ul {
					width: 63%;
					word-break: normal;
					padding-left: 2rem;

					li {
						line-height: 30px;

						& > span {
							font-size: 16px !important;
						}
					}
				}

				p {
					width: 60%;
					line-height: 30px;
					text-align: justify;

					span {
						font-size: 16px !important;
						line-height: 30px !important;
					}

					// &:nth-last-child(1) {
					// 	display: none;
					// }
				}

				// div > img {
				// 	// width: 35vw !important;
				// }

				.note {
					background-color: rgb(238, 238, 238);
					width: 58%;
					padding: 1rem;
					border-radius: 5px;
					display: flex;
					gap: 10px;
					border: 1px solid;
					font-size: 16px;
					font-weight: bold;
					// word-break: break-all;
					line-height: 25px;
					// margin-left: 12rem;
					img {
						width: 20px !important;
						height: 20px !important;
					}
				}
				.next {
					padding: 1rem 2rem;
					// height: 50px;
					// width: 100px;
					font-size: 20px;
					border-radius: 5px;
					border: none;
					outline: none;
					background-color: #e39c2f;
					display: flex;
					justify-content: center;
					align-items: center;
					// float: right;
					gap: 20px;
					font-weight: 600;
					margin-left: 10rem;
					margin-top: 1rem;
					cursor: pointer;

					& > img {
						width: 15px !important;
						height: 15px !important;
					}

					&:hover {
						color: white;
						background-color: black;
					}
				}
			}
			#videos {
				min-width: 75vw;
				padding: 0px 16rem;
				display: flex;
				flex-direction: column;
				margin-top: -8px;

				// .videoFooter {
				// 	width: 100%;
				// 	display: flex;
				// 	justify-content: center;
				// 	align-items: baseline;
				// 	padding-top: 1rem;
				// 	margin-top: 1rem;

				// 	.next {
				// 		padding: 0px 2rem;
				// 		height: 50px;
				// 		font-size: 20px;
				// 		border-radius: 5px;
				// 		border: none;
				// 		outline: none;
				// 		background-color: #e39c2f;
				// 		display: flex;
				// 		justify-content: center;
				// 		align-items: center;
				// 		gap: 20px;
				// 		font-weight: 600;
				// 		margin-left: 2rem;
				// 		cursor: pointer;

				// 		&:hover {
				// 			color: white;
				// 			background-color: black;
				// 		}
				// 	}
				// }
			}
			#quiz {
				width: 75vw;
				height: calc(100vh - 300px);
				overflow-y: scroll;
				scroll-behavior: smooth;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-left: 15rem;

				&::-webkit-scrollbar {
					width: 10px;
				}

				&::-webkit-scrollbar-track {
					background-color: white;
					height: 100vh;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #f4b34a6c;
					height: 20px;
					border-radius: 0.5rem;
				}

				.next {
					padding: 0px 2rem;
					min-height: 50px;
					font-size: 20px;
					border-radius: 5px;
					border: none;
					outline: none;
					background-color: #e39c2f;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 20px;
					font-weight: 600;
					margin-left: 2rem;
					margin-top: 1rem;
					cursor: pointer;

					&:hover {
						color: white;
						background-color: black;
					}
				}
			}

			#compiler {
				width: 75vw;
				display: flex;
				flex-direction: column;
				align-items: center;

				.next {
					padding: 0px 2rem;
					height: 50px;
					font-size: 20px;
					border-radius: 5px;
					border: none;
					outline: none;
					background-color: #e39c2f;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 20px;
					font-weight: 600;
					margin-left: 2rem;
					margin-top: 1rem;
					cursor: pointer;

					&:hover {
						color: white;
						background-color: black;
					}
				}
			}
		}
	}
}

// @media screen and (min-width: 700px) {

// 	#notes
// 	{}
// }
