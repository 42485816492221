@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

.titleContainer {
	display: flex;
	border: 1px solid;
	padding: 1rem 2rem;
	font-family: "Montserrat";
	overflow: hidden;
	justify-content: space-between;
	div {
		font-size: 1.3rem;
		font-weight: 600;
		width: 100%;
		display: flex;

		div:nth-child(1) {
			display: flex;
			align-items: center;
			column-gap: 0.3rem;

			.button3 {
				color: #e39c2f;
				font-weight: 600;
				background: none !important;
				border: none;
				outline: none;
				font-size: 1.2rem;
	
				cursor: pointer;
			}
			
			.button4 {
				color: #e39c2f;
				font-weight: 600;
				background: none !important;
				border: none;
				outline: none;
				font-size: 1.2rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 200px;
				cursor: pointer;
			}
		}
		div:nth-child(2) {
			display: flex;
			justify-content: flex-end;

			.button3 {
				background-color: #e39c2f;
				font-weight: 600;
				border: none;
				outline: none;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					border: none;
					background-color: #f4b34a6c;
				}
			}
		}

		span {
			margin-left: 1rem;
			color: black;
		}
	}
	div:nth-child(1) {
		color: #e39c2f;
	}
}
