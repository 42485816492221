.container1 {
	// border: 2px solid black;
	padding: 1rem;
	height: 80vh;
	width: 100%;
	overflow-y: scroll;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: white;
		height: 100vh;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #f4b34a6c;
		height: 20px;
		border-radius: 0.5rem;
	}

	.profile {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;

		& > div {
			position: relative;
			img {
				width: 130px;
				height: 130px;
				border-radius: 50%;
			}
			.editIcon {
				position: absolute;
				top: 70%;
				right: 0%;
				background-color: black;
				color: white;
				border-radius: 50%;
				padding: 5px;
				cursor: pointer;
			}

			.file_upload {
				position: absolute;
				top: 70%;
				right: 0%;
				opacity: 0;
				width: 50px;
				cursor: pointer;
			}
		}
	}

	.tabs {
		padding-left: 2rem;

		ul {
			list-style: none;
			padding-top: 10px;

			button {
				padding: 1rem;
				background-color: #f4b34a;
				margin-bottom: 10px;
				border-radius: 5px;
				font-weight: 500;
				cursor: pointer;
				position: relative;
				transition: 0.2s linear;
				border: none;
				outline: none;
				width: 100%;

				button {
					display: flex;
					align-items: center;
					gap: 20px;
					background: none;
					outline: none;
					border: none;
					font-weight: 500;
					font-size: 16px;
				}

				&:hover::after {
					content: ">";
					position: absolute;
					top: 23%;
					right: 5%;
					color: black;
					font-size: 25px;
					font-weight: 500;
					transition: 0.2s linear;
				}

				&.active {
					background-color: rgb(56, 56, 56);
					color: white;
					transition: 0.2s linear;

					&::after {
						content: ">";
						position: absolute;
						top: 23%;
						right: 5%;
						color: white;
						font-size: 25px;
						font-weight: 500;
						transition: 0.2s linear;
					}
				}

				& > p {
					display: flex;
					align-items: center;
					gap: 20px;
				}
			}
		}
	}
}
