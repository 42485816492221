@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&family=Montserrat:wght@400;500;600;700;800&family=Poppins:wght@600;700&family=Roboto:wght@300;400&display=swap");
$font06: 0.6rem;
@mixin flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signContainer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;

	.signLeft {
		display: flex;
		justify-content: center;
		align-items: center;
		// padding-top: 10%;

		flex-direction: column;

		.logo {
			width: 30%;
			// height: 4rem;
			margin-bottom: 0.5rem;
			// margin-top: 5rem;
		}
		.signText {
			margin: 1rem 0;
			font-weight: 500;
		}
		.form {
			width: 60%;
			display: flex;
			flex-direction: column;

			.logInputs {
				width: 100%;
				display: block;
				margin: 1rem 0;
				border-radius: 0.2rem;
				border: 1px solid #000222;
				padding: 0.5rem 1rem;
				outline: 0;
			}
			.logInput {
				width: 100%;
				display: block;
				margin: 1rem 0;
				border-radius: 0.2rem;
				// border: 1px solid #000222;
				padding: 0.5rem 1rem;
				outline: 0;
				display: flex;
				justify-content: center;
				input {
					border: none;
					text-align: center;
					outline: none;
					border-bottom: 1px solid;
					justify-self: center;
					width: 2.5rem;
				}
				.OtpInput {
					display: flex;
					gap: 2rem;
					width: 2.5rem !important;
				}
			}
			.logIn {
				display: none;
			}
			.forgetDiv {
				@include flex();
				justify-content: space-between;
				font-size: 0.9rem;
				font-weight: 500;
				margin-top: 2rem;
				.checkBoxDiv {
					@include flex();
					margin-top: 1rem;
					.checkBox {
						margin-right: 0.5rem;
					}
				}
				.forget {
					color: #00bfff;
					text-decoration: none;
				}
			}
			.signBtn {
				a {
					text-decoration: none;
					color: #000222;
				}
				width: fit-content;

				display: flex;
				margin: auto;
				margin-top: 2rem;
				border: 1px solid;
				border-radius: 0.2rem;
				// padding: 0.5rem;
				cursor: pointer;
				font-weight: 500;
				background-color: rgba(128, 128, 128, 0.048);
			}
			.orSign {
				font-size: $font06;
				font-weight: 500;
				text-align: center;
				margin-bottom: 1rem;
			}
			.auotSignBtnDiv {
				@include flex();
				gap: 2rem;
				.autoBtn {
					@include flex();
					gap: 0.5rem;
					font-size: $font06;
					color: white;
					border-radius: 0.2rem;
					border: none;
					.google_logo {
						width: 15%;
					}
				}
				.google {
					cursor: pointer;
					padding: 0.5rem 1rem;
					background-color: #d64837;
					text-decoration: none;

					p {
						text-decoration: none;
						color: white;
						font-size: 0.9rem;
					}
				}
				.facebook {
					cursor: pointer;
					padding: 0.5rem 2rem;
					background-color: #3177f2;
					text-decoration: none;

					gap: 0;
					p {
						text-decoration: none;
						color: white;
						font-size: 0.9rem;
					}
					.fb_logo {
						width: 30%;
					}
				}
			}
			.signUp {
				font-size: $font06;
				text-align: center;
				margin-top: 1rem;
			}
			.scndSpan {
				text-decoration: underline;
			}
		}
	}

	.signRight {
		background-image: url(../media/close-up-female-student-typing-laptop-table.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		height: 100vh;
	}
	.layer {
		@include flex();
		flex-direction: column;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);

		.heading {
			text-align: center;
		}
	}
	.rightContainer {
		margin-top: 4rem;
		@include flex();
		flex-direction: column;
		gap: 2rem;
		div {
			display: flex;
			gap: 0.5rem;
			align-items: baseline;
		}
	}
	.boldText {
		font-weight: 700;
		font-size: 1.3rem;
	}
	.smallText {
		font-size: 1rem;
	}
}

.errorMsg {
	color: rgb(209, 54, 54);
	font-size: 0.8rem;
	text-transform: capitalize;
	font-weight: 600;
}
.otpLogin {
	color: white;
	font-weight: 700;
	cursor: pointer;
	text-align: center;
	background-color: transparent;
	border: none;
	height: fit-content;
	width: fit-content;
	background-color: #f4b34a;
	border: 1px solid #f4b34a;
	padding: 0.8rem 3rem;
	font-size: 1rem;
	border-radius: 0.5rem;
	margin-top: 1rem;

	&:hover {
		background-color: rgb(18, 18, 18);
		color: white;
	}
}
.logIn {
	display: none;
}
.mailInput {
	width: 100%;
}
.forgot {
	color: #f4b34a;
	cursor: pointer;
}

.doFlex {
	margin: 0 auto;
	margin-top: 1rem;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.createAc {
	text-align: center;
	color: #f4b34a;
	cursor: pointer;
	margin-top: 1rem;
	font-weight: 600;
	font-size: 1rem;
	border: none;
}
.passLogin {
	color: #f4b34aee;
	cursor: pointer;
}
