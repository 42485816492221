@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

.tbContainer {
	width: 100%;
	font-family: "Montserrat";
	position: fixed;

}
.flexTop{
	display: flex;
	justify-content: space-between;

}
.flex {
	display: flex;
}
.left {
	flex: 1;
	height: 90vh;
	scroll-behavior: smooth;
	overflow-y: scroll;
	border-right: 2px solid;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: white;
		height: 100vh;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #f4b34a6c;
		height: 20px;
		border-radius: 0.5rem;
	}
}

.head {
	display: flex;
	justify-content: space-between;
	padding: 2.5% 10%;
	align-items: center;

	.calendar {
		font-size: 1.3rem;
		font-weight: 700;
		font-weight: 600;
		color: #f4b34a;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		position: relative;

		.daypicker {
			position: absolute;
			background-color: white;
			z-index: 1;
			box-shadow: 0px 2px 5px grey;
			border-radius: 5px;
			top: 20px;
			right: -20px;
			padding: 1rem;
			animation: calender 0.3s ease-in-out;
			transition: 2s linear;

			@keyframes calender {
				0% {
					position: absolute;
					top: 0px;
					display: none;
				}
				100% {
					position: absolute;
					top: 20px;
					// transition: 2s linear;
				}
			}
		}
	}
	.schedule {
		font-size: 1.6rem;
		font-weight: 650;
		// padding: 2rem;
		span {
			font-size: 1.2rem;
			font-weight: 400;
		}
	}
}
.slic {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	display: flex;
	align-items: center;

	& > div {
		flex: 1;
		padding: 5px 10px 5px 5px;
		margin-left: -2rem !important;
	}
}
.h3 {
	height: 3.5rem;
	width: 3.5rem;
	border-radius: 0.5rem;
	font-size: 18px;
	font-weight: bold;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fae3c0;
	position: relative;
	box-shadow: 1px 1px 5px rgb(213, 213, 213);
	cursor: pointer;
}
.ye {
	height: 3rem;
	width: 3rem;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fef6e9 !important;
}
.classes {
	padding: 4% 10% 10%;
	border-radius: 1rem;

	.grids {
		display: flex;
		flex-direction: column;
		// grid-template-rows: 0.6fr 4.0fr;
		box-shadow: 0px 0px 0.5rem grey;
		border-radius: 1rem;
	}

	.top {
		font-size: 1.5rem;
		font-weight: 600;
		border: 1px solid #f4b34a;
		background-color: #f4b34a;
		padding: 1rem 2rem 2rem 2rem;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		height: fit-content;

		& > div:nth-child(1) {
			font-weight: 500;
		}
		& > div:nth-child(2) {
			margin-top: 0.5rem;
		}
	}
	.bottom {
		background-color: #fef6e9;
		border-radius: 1rem;
		display: grid;
		a {
			height: fit-content;
			background-color: white;
			border-radius: 1rem;
		}
		a:nth-child(even) {
			background-color: #fef6e9;
		}
		a:nth-last-child(1) {
			span::before {
				content: "" !important;
				font-size: 0rem !important;
				color: #000222;
			}
		}
	}
}
.bottomContent {
	display: flex;
	padding: 2rem;
	padding-right: 6rem;
	width: 100%;
	border: none;
	outline: none;
	background: none;
	font-size: 1.5rem;
	justify-content: space-between;
	cursor: pointer !important;
	p {
		width: 100%;
		text-align: left;
		word-break: normal;
		cursor: pointer !important;
		
	}

	&:disabled {
		color: grey;
		cursor: pointer;

		img {
			width: 100% !important;

		}
	}

	span {
		position: relative;
		justify-content: center;
		display: flex;
		align-items: center;
		border: 1px solid black;
	}
	span::before {
		position: absolute;
		content: "|";
		font-size: 6.5rem;
		color: #000222;
		top: 10%;
	}

	.circle {
		height: 2rem;
		width: 2rem;
		border: 2px solid;
		border-radius: 50%;
		background-color: #000222;
	}
}
.bottom::-webkit-scrollbar {
	display: none;
}
.right {
	flex: 0.65;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem;
	height: 80vh;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-track {
		background-color: white;
		height: 20vh;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #f4b34a6c;
		height: 10px;
		border-radius: 0.5rem;
	}

	.rightTop,
	.rightBottom {
		width: 90%;
		border-radius: 1rem;
		box-shadow: 1px 1px 9px 2px rgba(128, 128, 128, 0.493); 
	}

	.rightTop > .progressTop {
		font-size: 1.2rem;
		font-weight: 600;
		padding: 0.5rem 2rem;
		background-color: #f4b34a;
		border-radius: 1rem 1rem 0px 0px;
	}
	.rightTop > .progressBottom {
		padding: 1rem 3rem 1rem 2rem;
		font-size: 1.2rem;
		font-weight: 500;

		div {
			div {
				& > span {
					margin-left: 1rem;
				}
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
			// column-gap: 1rem;
			// grid-template-columns: 0.1fr 1fr 1fr;

			.circle {
				height: 1rem;
				width: 1rem;
				border: 2px solid;
				border-radius: 50%;
				background-color: #000222;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.rightBottom {
		width: 90%;
		margin-top: 1rem;
		padding: 1rem 1rem;
		display: grid;
		gap: 15px;

		div {
			text-align: center;
			font-size: 1.1rem;
			margin: auto;
		}
		hr {
			// text-align: center;
			margin: auto;
			width: 85%;
		}
		.bold {
			font-size: 1.3rem;
			font-weight: 400;
		}
		.insideGrid {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			text-align: left;
			justify-content: center;
			& > div {
				width: 100%;
			}
			div:nth-last-child(3) {
				text-align: left;
			}
			div:nth-last-child(1) {
				text-align: right;
			}
		}
	}
	.and {
		margin-top: 0.5rem !important;
		span {
			opacity: 30%;
		}
	}
	.complete {
		font-weight: 300;
		margin-top: 0.2rem !important;
	}
}
.bottomInGrid {
	width: 85%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: left;
	justify-content: center;
	font-size: 0.8rem;
	div {
		width: 100%;
	}
	div:nth-last-child(3) {
		text-align: left;
	}
	div:nth-last-child(1) {
		text-align: right;
	}
}

@media screen and (min-width: 901px) and (max-width: 1280px) {
	.classes {
		padding: 4% 10% 15%;
	}
}
@media (min-width: 481px) and (max-width: 768px) {
	.left {
		flex: 2;
	}
	.slic {
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		display: flex;
		align-items: center;
		& > div {
			flex: 2 !important;
			padding: 5px 10px 5px 5px;
			margin-left: -2rem !important;
		}
	}
	.bottomContent{
			p {
				margin-top: 10px;
				font-size: 16px !important ;
				line-height: 21px !important;
			}
		
	}
	.rightTop > .progressTop {
		font-size: 1.2rem;
		font-weight: 600;
		padding: 0.5rem 2rem;
		background-color: #f4b34a;
		border-radius: 1rem 1rem 0px 0px;
	}
	.rightTop > .progressBottom {
		padding: 1rem 1rem 1rem 0.1rem !important;
		font-size: 0.8rem !important;
		font-weight: 500;

		div {
			div {
				& > span {
					margin-left: 1rem;
				}
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
			// column-gap: 1rem;
			// grid-template-columns: 0.1fr 1fr 1fr;

			.circle {
				height: 0.8rem !important;
				width: 0.8rem !important;
				border: 2px solid;
				border-radius: 50%;
				background-color: #000222;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.rightBottom {
		width: 90%;
		margin-top: 1rem;
		padding: 1rem 1rem;
		display: flex !important;
		flex-direction: column !important;
		gap: 15px;

		div {
			text-align: center;
			font-size: 0.7rem !important;
			margin: auto;
		}
		hr {
			// text-align: center;
			margin: auto;
			width: 85%;
		}}

}
