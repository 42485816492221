.container {
	width: 100%;
	height: 80vh;
	padding: 1rem 0rem;
	display: flex;
	flex-direction: column;
}

.courseDiv {
	width: 80%;
	height: 45%;
	margin: 0 auto;
	margin-top: 2rem;
}
.courseDiv::-webkit-scrollbar {
	display: none;
}
.CourseName {
	width: 100%;
	height: 100%;
	box-shadow: 1px 0.5px 8px 0px rgba(128, 128, 128, 0.454);
	border-radius: 0.5rem;
	overflow: hidden;
	// background-color: #efefef8c;
	display: grid;
	justify-content: center;
	position: relative;
	// padding: 1rem 0px;
}
.CourseProgress {
	width: 100%;
	margin-left: 5rem;
	height: 100%;

	position: relative;
	border-radius: 0.5rem;
}
.courseTitle {
	display: flex;
	align-items: flex-start;
	width: 80vw;
	justify-content: space-between;
	padding: 1rem;
	img {
		width: 22%;
		margin-right: 1rem;
		align-self: center;
		// border: 2px solid;
		// height: 100%;
	}
}

.buttons {
	display: flex;
	padding: 0rem 1rem;
	padding-top: 0px;

	div {
		p {
			margin-top: 10px;
			line-height: 25px;
		}
	}
}
.mentor {
	padding: 0.5rem 2rem;
	border: none;
	outline: none;
	background-color: #f4b34a;
	color: white;
	border-radius: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	transition: 0.2s linear;

	&:hover {
		background-color: black;
		transition: 0.2s linear;
	}
}

.startProgram {
	padding: 0.5rem 2rem;
	font-size: 1.2rem;
	color: white;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: #f4b34a;
	border-radius: 0.5rem;
	cursor: pointer;
	transition: 0.2s linear;
	position: absolute;
	right: 2rem;
	bottom: 0.5rem;
	margin: 1rem;
	&:hover {
		background-color: black;
		transition: 0.2s linear;
	}
}
.progressLevel {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	color: #efefef;
	box-shadow: 1px 0.5px 8px 0px grey;
}
@media (min-width: 481px) and (max-width: 768px) {
	h1{
		font-size: 17px !important;
	}
	.container {
		width: 100%;
		height: 80vh;
		padding: 1rem 0rem;
		display: flex;
		flex-direction: column;
		div {
			p {
				margin-top: 10px;
				font-size: 12px ;
				line-height: 20px !important;
			}
		}}
	.startProgram {
		padding: 0.3rem 1rem !important;
		font-size: 1rem !important;

	}
}
@media (min-width: 769px) and (max-width: 1024px) {
	.container {
		width: 100%;
		height: 80vh;
		padding: 1rem 0rem;
		display: flex;
		flex-direction: column;
h1{
	font-size: 21px;
}
div {
	p {
		margin-top: 10px;
		font-size: 15px;
		line-height: 25px;
	}
}
	}
	
}