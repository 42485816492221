.invoiceContainer {
	height: 80%;
	width: 100%;
	overflow: scroll;
	display: flex;
	flex-direction: column;

	align-items: center;
	img {
		height: 10rem;
		width: 100%;
		padding: 2rem;
	}
	.grid {
		display: flex;
		justify-content: space-between;
		.right {
			padding: 2rem;
			.original {
				font-size: 1.2rem;
				font-weight: 600;
				color: grey;
				text-align: right;
			}
			.tax {
				font-size: 2rem;
				font-weight: 600;
				color: grey;
				text-align: right;
			}
			div {
				color: grey;
				margin-bottom: 0.6rem;
				text-align: right;
			}
		}
	}
	hr {
		width: 98%;
		margin: 0 auto;
		border: 0.5px solid rgba(128, 128, 128, 0.336);
	}

	.grid2 {
		display: flex;
		justify-content: space-between;
		.heading {
			font-size: 1.4rem;
			font-weight: 500;
			color: black;
		}
		.Supplier {
			color: grey;
			padding: 2rem;
			.heading {
				font-size: 1.4rem;
				font-weight: 500;
				color: black;
			}
			div {
				margin: 0.5rem;
			}
		}
		.Recipient {
			color: grey;
			padding: 2rem;
			.heading1 {
				font-size: 1.4rem;
				// float: right;
				font-weight: 500;
				color: black;
			}
			div {
				text-align: end;
				margin: 0.5rem;
			}
		}
	}

	.download {
		cursor: pointer;
		float: right;
		margin-right: 2rem;
		margin-bottom: 2rem;
		background-color: #000222;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: 0.6rem 1.5rem;
		border-radius: 0.5rem;
	}
	.table1 {
		max-width: 98%;
		margin: 10px auto;
		padding: 0rem 0.7rem;
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		column-gap: 0.5rem;
		font-size: 0.8rem;
		background-color: #f8f8f8;
		border-bottom: 0.5px solid grey;
		font-weight: 600;
		p {
			width: 6rem;
		}
		.desc {
			width: 9rem;
		}
	}
	.table {
		max-width: 98%;
		margin: 0 auto;
		padding: 0rem 0.7rem;
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		column-gap: 0.6rem;
		font-size: 0.8rem;

		p {
			width: 6rem;
		}
		.desc {
			width: 9rem;
		}
	}
	.pay {
		padding: 2rem 2rem 0rem 2rem;
		p {
			color: grey;
		}
	}
}
.invoiceContainer::-webkit-scrollbar {
	display: none;
}
.bill {
	border: 1px solid;
	margin-top: 2rem;
}
