.container {
	// height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.logo {
	width: 30%;
	// height: 4rem;
	// margin-bottom: 2rem;
}
.steps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	margin-top: 3rem;
}
.step {
	height: 20px;
	width: 20px;
	border: 2px solid #f4b34a;
	border-radius: 50%;
	background-color: #f4b34a;
}
.body {
	// height: 50%;
	width: 80%;
	display: flex;
	flex-direction: column;
	padding-top: 3rem;
	align-items: center;
	margin-top: 2rem;
	position: relative;
}
.bottom {
	width: 100%;
	display: flex;
	justify-content: space-between;
	// position: absolute;
	// bottom: 0;

	.backbutton {
		cursor: pointer;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		background-color: #f4b34a;
		color: white;
		font-weight: 600;
		border: none;
		outline: none;
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: rgb(19, 19, 19);
			transition: 0.3s ease-in-out;
		}
	}

	div {
		cursor: pointer;
		padding: 0.5rem 3rem;
		border-radius: 0.5rem;
		background-color: #f4b34a;
		color: white;
		font-weight: 600;
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: rgb(19, 19, 19);
			transition: 0.3s ease-in-out;
		}
	}
}
.mailInput {
	width: 100%;
}
.StepsBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.OtpInput {
	display: flex;
	gap: 2rem;

	width: 2.5rem !important;
}
