.container{
    height: 80vh;
    overflow: scroll;
}

.grid{
    display: flex;
}
.imgParent{
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle{
    border: 1px solid ;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10%;
    position: relative;
    background-color: white;
    margin-top: 3rem;
    margin-right: 1.5rem;
}
.circle1{
    border: 1px solid ;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10%;
    margin-top: 3rem;
    position: relative;
    margin-right: 1.5rem;
    background-color: white;
    img{
        width: 30px;
    }

}
.circle::before{
    position: absolute;
    content: " ";
    border: 1px solid ;
    height: 42vh;
    z-index: -1;
   top: 10%;
}
.circle:last-child::before{
    content: " ";
    border: 0px solid !important;
    height: 0vh !important;  
}

.container::-webkit-scrollbar{
    display: none;
}
.CardsParent{
    width: 30%;
    border-radius: 0.5rem;
    height: 15rem;
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
}
.Cards{
    width: 100%;
    border-radius: 0.5rem;
    height: 98.5%;
    margin: 0 auto; 
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.102);
    position: relative;
    z-index: 1;
    
}
.CardsBack{
    width: 100%;
    position: absolute;
    height: 100%;
}
.CardsParent:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.417);
    .CardsBack{
    animation: CardsHov 4s linear infinite ;

}
}
@keyframes CardsHov{
    0% {
        background-color: #f4b34a;
        transform: rotate(0deg);
    }
  
    100% {
        background-color: #f4b34a;
        transform: rotate(360deg);

    }
}
.titles{
    width: 100%;
    position: relative;
    padding: 2rem;
    p{
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
    }
}
.button{
  
    font-size: 1.2rem;
    padding: 0.5rem 4rem;
    background-color: #f4b34a;
    color: white;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.329);
    cursor: pointer;
    z-index: -1;
}
.emptySpace{
    height: 100%;
    width: 60%;
}

.ButtonParent{
    position: absolute;
    bottom: 0;
    right: 0;
    padding:1rem 2rem;
    width: fit-content;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.bar{
    position: absolute;
    bottom: 0;

    display: flex;
    width: 100%;
}
@media (min-width: 481px) and (max-width: 768px) {
    .CardsParent{
        width: 95% !important;
        height: 16rem !important;   
    }
    .circle{
        border: 1px solid ;
        height: 40px;
        width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 10%;
        position: relative;
        background-color: white;
        margin-top: 3rem;
        margin-right: 1.5rem;
        img{
            width: 20px;
        }
    }   
    .circle1{
        border: 1px solid ;
        height: 40px;
        width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 10%;
        margin-top: 3rem;
        position: relative;
        margin-right: 1.5rem;
        background-color: white;
        img{
            width: 20px;
        }
    
    }
 
    .circle::before{
        position: absolute;
        content: " ";
        border: 1px solid ;
        height: 52vh;
        z-index: -1;
       top: 10%;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
 
    .CardsParent{
        width: 45% !important;
        height: 16rem !important;   
    }
    .circle{
        border: 1px solid ;
        height: 40px;
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 10%;
        position: relative;
        background-color: white;
        margin-top: 3rem;
        margin-right: 1.5rem;
    }
    .circle::before{
        position: absolute;
        content: " ";
        border: 1px solid ;
        height: 52vh;
        z-index: -1;
       top: 10%;
    }

}
  
  
  
  
  
  
  
  
  
  