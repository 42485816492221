.main {
	margin-left: -8rem;
	h3 {
		font-weight: 700;
	}
	h1 {
		font-weight: 700;
		font-size: 30px;
	}

	.quizBody {
		padding: 1rem 0px;
		p {
			max-width: 30rem;
		}

		div {
			padding: 10px 2rem;
			border: 1px solid grey;
			box-shadow: 0px 4px 5px grey;
			max-width: 30rem;
			word-break: break-word;
			border-radius: 5px;
			margin: 1rem 0px;
			cursor: pointer;

			&:hover {
				background-color: #f4b34a98;
			}
		}
	}

	button {
		padding: 0px 2rem;
		height: 50px;
		font-size: 20px;
		border-radius: 5px;
		border: none;
		outline: none;
		background-color: #e39c2f;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		font-weight: 600;
		margin-left: 2rem;
		margin-bottom: 5rem;
	}
}

.nomain {
	margin-left: -6rem;
}
