.bodyContainer {
	display: flex;
	width: 100%;
	height: 100vh;
}

.bodyDiv2 {
	width: 100%;
	border-left: 1px solid grey;
	// transition: 1s ease-in-out;
}

.ask {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 60%;
	margin-top: 2rem;
}

.block {
	max-width: 25%;
	min-width: 25%;
	flex: 6;
	height: 80vh;
	transition: 0.8s;
	position: relative;
	cursor: pointer;

	& > section {
		height: 80vh;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none;
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: white;
			height: 100vh;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #f4b34a6c;
			height: 20px;
			border-radius: 0.5rem;
		}
	}

	.arrowOut {
		border: 1px solid grey;
		border-radius: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5rem;
		height: 1.5rem;
		position: absolute;
		right: 0;
		margin: auto;
		margin-right: -0.9rem;
		background-color: #f2f2f2;
		margin-top: 1.5rem;
		cursor: pointer;
		z-index: 1;
	}

	.arroww {
		transform: rotate(180deg);
		transition: 0.8s;
		width: 10px;
	}
}
.null {
	max-width: 0px;
	min-width: 0px;
	border-left: 1px solid grey;
	transition: 0.8s;
	// display: none;
	position: relative;

	.arrowOut {
		border: 1px solid grey;
		border-radius: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5rem;
		height: 1.5rem;
		position: absolute;
		right: 0;
		margin: auto;
		margin-right: -1rem;
		background-color: #f2f2f2;
		margin-top: 1.5rem;
		z-index: 1;
		cursor: pointer;
	}

	.arroww {
		width: 0.6rem;
		transform: rotate(0deg);
		transition: 0.8s;
	}
}
.headerSection1 {
	text-align: center;
	font-size: 1.3rem;
	font-weight: 600;
}
.coll {
	width: 100%;
	margin: 0 auto;
	background-color: transparent;
	border: 0px;
	height: 100%;
	overflow: scroll;
	padding-right: 10px;

	&::-webkit-scrollbar {
		display: none;
	}
}
.cont {
	display: flex;
	align-items: center;
	background-color: transparent;
	width: 100%;
	text-align: left;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	font-size: 1.2rem;
	position: relative;
	border: none;
	outline: none;
	border-bottom: 1px solid grey;

	.headerSection2 {
		cursor: pointer;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		width: 100%;

		.titles {
			width: 80%;
			word-wrap: break-word;
		}
		.tick {
			background-color: black;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			padding: 3px;
			display: grid;
			place-items: center;
		}
	}

	&.active {
		background-color: #f4b34a98;
		color: black;
		transition: 0.2s linear;

		// &::after {
		// 	// content: ">";
		// 	position: absolute;
		// 	top: 30%;
		// 	right: 5%;
		// 	color: black;
		// 	font-size: 25px;
		// 	font-weight: 500;
		// 	transition: 0.2s linear;
		// }
	}
}

.curve {
	height: 100%;
	position: absolute;
	left: 0;
	margin: 0 auto;
	width: 3%;
}

// .cont:focus .curve {
// 	height: 2rem;
// 	position: absolute;
// 	left: 0;
// 	margin: 0 auto;
// 	width: 3%;
// 	background: #f4b34a;
// 	border-bottom-right-radius: 1rem;
// 	border-top-right-radius: 1rem;
// }
.vdo_container {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}
.headerSection1 {
	text-align: left;
}
