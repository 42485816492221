.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	height: 80vh;
}
.button {
	width: 20%;
	margin: 0 auto;
	margin-top: 5rem;
	background-color: #f4b34a;
	padding: 1rem;
	border: 1px solid #5c5a5758;
	border-radius: 0.5rem;
}
