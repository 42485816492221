.main {
	padding: 2rem;
	overflow-y: scroll;
	height: 80vh;

	&::-webkit-scrollbar {
		width: 10px;
	} 

	&::-webkit-scrollbar-track {
		background-color: white;
		height: 100vh;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #f4b34a6c;
		height: 20px;
		border-radius: 0.5rem;
	}
}

.thumbnail {
	object-fit: contain;
	width: 100%;
}

.card {
	cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1024px) {
	.css-c6d3tm-MuiCardMedia-root{
		display: block;
		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		-webkit-background-position: center;
		background-position: center;
	   width: 100% !important;
		object-fit: cover;
		width: 45% !important;
		object-fit: center;	}
}