.container2 {
	border-left: 1px solid grey;
	height: 100vh;
	.title {
		width: 100%;
		border-bottom: 1px solid grey;
		h2 {
			padding: 1rem;
		}
	}
	.main {
		width: 100%;
		height: 70vh;
		overflow-y: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: white;
			height: 100vh;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #f4b34a6c;
			height: 20px;
			border-radius: 0.5rem;
		}
		padding: 1rem;
		form {
			& > div:nth-child(1),
			& > div:nth-child(5),
			& > div:nth-child(6),
			& > div:nth-child(7) {
				display: flex;
				gap: 40px;
				padding: 1rem;

				& > span {
					& > label {
						font-weight: 500;
					}
					display: flex;
					flex-direction: column;
					gap: 10px;
					input {
						width: 30rem;
						height: 50px;
						border-radius: 10px;
						border: none;
						outline: none;
						padding-left: 1rem;
						box-shadow: 0px 2px 5px grey;
					}
					& > span {
						color: red;
					}
					.gender {
						width: 30rem;
						height: 50px;
						border-radius: 10px;
						border: none;
						outline: none;
						padding-left: 1rem;
						box-shadow: 0px 2px 5px grey;
					}
				}
			}
			& > div {
				padding: 1rem;
				& > span {
					& > label {
						font-weight: 500;
					}
					display: flex;
					flex-direction: column;
					gap: 10px;
					input {
						width: calc(100vw - 29rem);
						height: 50px;
						border-radius: 10px;
						border: none;
						outline: none;
						padding-left: 1rem;
						box-shadow: 0px 2px 5px grey;
					}
					& > span {
						color: red;
					}

					textarea {
						width: calc(100vw - 29rem);
						height: 150px;
						border-radius: 10px;
						border: none;
						outline: none;
						padding: 1rem;
						box-shadow: 0px 2px 5px grey;
					}
				}
			}
			#submit {
				padding: 1rem 3rem;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: #f4b34a6c;
				cursor: pointer;
				font-size: 16px;
				font-weight: 500;
				margin-left: 1rem;

				&:hover {
					background-color: #f4b34a;
				}
			}
		}
	}
}

.pass {
	position: relative;

	.showIcon {
		position: absolute;
		right: 10%;
		top: 55%;
	}
}
@media (min-width: 481px) and (max-width: 768px) {
	.container2 {
		width: 70% !important;
		border-left: 1px solid grey;
		height: 100vh;}
}