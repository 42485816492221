@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat" !important ;

}
body
{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.rdp-day_selected
{
  background-color: #f4b34a !important;
}

.react-calendar {
  width: 100% !important;
  height: fit-content !important;
  border: 1px solid !important;
}
.react-calendar__month-view__weekdays {
  position: sticky !important;
  top: 0 !important;
  overflow: hidden;
  z-index: 100 !important;
  background-color: white;
}
.react-calendar__month-view__weekdays div {
  font-size: 1.5rem;
  border: 1px solid;
  padding: 1rem 0rem;
}
.react-calendar__month-view__weekdays div abbr {
  text-decoration: none !important;
}
.react-calendar__tile {
  padding: 3rem !important;
  font-size: 1.5rem;
  border: 1px solid !important;
}
/* .react-calendar__month-view__days__day--weekend {
  color: black !important;
} */
.react-calendar__navigation {
  font-size: 1.5rem;
  padding: 1rem 0rem;
  /* display: none !important; */
}
.react-calendar__month-view__days {
  z-index: -100 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: grey !important;
}
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  font-size: 1.5rem;
  font-weight: 800;
  height: fit-content;
}
.ant-collapse-expand-icon {
  position: absolute;
  right: 0;
  margin: auto;
}
.slick-slider {
  width: fit-content;
  align-items: center;
  padding: 0rem 0rem 0rem 3rem;
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: ">";
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}
.slick-prev:before,
[dir="rtl"] .slick-prev:before {
  content: "<";
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}

@media screen and (max-width : 600px) {

  body
  {
    display: none;
  }
  
}