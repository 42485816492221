.main
{
    display: flex;

    section
    {
        min-width: 50%;
        height: 100vh;

        &:first-child
        {
            border-right: 1px solid grey
        }
    }
}