@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Montserrat";
	padding: 10px 2rem 10px 4rem;

	& > img {
		width: 20vmin;
	}
	.flex {
		width: 50%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		cursor: pointer;

		.pause {
			border: 1px solid #f4b34a;
			background-color: #e39c2f;
			height: fit-content;
			display: flex;
			gap: 10px;
			align-items: center;
			padding: 0.5rem 2rem;
			border-radius: 0.5rem;
			font-size: 1.2rem;
			font-weight: 600;
			// color: black;
			text-decoration: none;
			margin-right: 2rem;
			cursor: pointer;
			span {
				font-weight: 800;
				margin-bottom: 0.2rem;
				font-size: 1rem;
			}
		}
		img {
			width: 50px;
			box-shadow: 0px 0px 5px #f4b34a;
			cursor: pointer;
		}
	}
}

.link {
	color: black;

	&:hover {
		color: black;
	}
}

.dropdown {
	ul {
		li:last-child {
			a {
				color: black;
			}

			&:hover {
				background-color: rgb(247, 65, 65) !important;
				a {
					color: white;
				}
			}
		}
	}
}

@media screen and (min-width: 900px) {
	.headerContainer {
		display: flex;
		justify-content: space-between;
		.flex {
			width: 50%;
			display: flex;
			justify-content: flex-end;
			gap: 10px;
			cursor: pointer;
			img {
				width: 50px;
				cursor: pointer;
			}
		}
	}
}
@media (min-width: 481px) and (max-width: 768px) {
	.pause {
		border: 1px solid #f4b34a;
		background-color: #e39c2f;
		height: fit-content;
		display: flex;
		gap: 10px;
		align-items: center;
		padding: 0.5rem 2rem;
		border-radius: 0.5rem;
		font-size: 1rem !important;
	}
}