// @font-face {
// 	font-family: textur;
// 	src: url(../media/fonts/ten.ttf);
// }

// .main {
// 	width: 100vw;
// 	height: 100vh;
// 	display: flex;
// 	place-items: center;
// 	overflow: scroll;
// 	padding: 2rem 0px;

// 	.outline {
// 		width: 70%;
// 		min-height: 100%;
// 		margin: 1cm auto;
// 		border: 1px #d3d3d3 solid;
// 		overflow: hidden;
// 		border-radius: 5px;
// 		background: #f4b34a;
// 		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
// 		position: relative;
// 		// overflow: scroll;

// 		.left {
// 			position: absolute;
// 			background-color: white;
// 			height: 150%;
// 			width: 90%;
// 			bottom: -25%;
// 			right: 0px;
// 			border-radius: 50% 0px 0px 50%;
// 			z-index: 1;
// 		}

// 		.iso {
// 			position: absolute;
// 			width: 100%;
// 			height: 100%;
// 			// z-index: 10;
// 			// rotate: 270deg;
// 			// left: 12%;
// 			// bottom: 2%;
// 			// transform-origin: bottom left;
// 			// h1 {
// 			// 	display: flex;
// 			// 	align-items: center;
// 			// 	font-size: 70px;
// 			// 	transform: scale(1, 1.5);
// 			// 	// padding-left: 0.5rem;
// 			// 	// font-family: textur !important;
// 			// 	// font-weight: 500;

// 			// 	& > img {
// 			// 		width: 100px;
// 			// 		height: 70px;
// 			// 	}
// 			// }
// 		}
// 		.right {
// 			width: 100%;
// 			height: 180%;
// 			position: absolute;
// 			z-index: 1;
// 			// border: 2px solid red;
// 			display: flex;
// 			flex-direction: column;
// 			// justify-content: flex-end;
// 			align-items: end;

// 			.certify {
// 				text-align: center;
// 				width: 80%;
// 				font-weight: 500;
// 				font-size: 20px;

// 				p:nth-child(2) {
// 					// border: 1px solid red;
// 					display: flex;
// 					justify-content: center;
// 					align-items: center;
// 					margin: 0.7rem 0px;
// 					span {
// 						width: 50%;
// 						margin-left: 1rem;
// 						border-bottom: 3px dotted black;
// 						text-align: start;
// 						padding-left: 1rem;
// 					}
// 				}
// 				p:nth-child(5) {
// 					margin: 0.3rem 0px;
// 					font-size: 16px;
// 					// padding-top: 2rem;
// 				}
// 				p:nth-child(6) {
// 					margin-top: 1.2rem;
// 					font-size: 16px;
// 					// padding-top: 2rem;
// 				}
// 				p:nth-child(8) {
// 					// margin: 1rem 0px;
// 					// font-size: 16px;
// 					margin-top: 0.5rem;
// 					padding-right: 1rem;
// 				}
// 				p:nth-child(9) {
// 					margin: 1rem 0px;
// 					display: flex;
// 					align-items: center;
// 					div {
// 						display: flex;
// 						width: 70%;
// 						justify-content: space-between;
// 						align-items: center;
// 						position: relative;

// 						& > span {
// 							margin-left: 1rem;
// 							padding-left: 1rem;
// 							width: 50%;
// 							text-align: start;
// 							border-bottom: 3px dotted black;
// 						}
// 						h5 {
// 							position: absolute;
// 							font-size: 16px;
// 							font-weight: bold;
// 							// margin-top: 2rem;
// 							text-decoration: overline;
// 							right: 0;
// 							top: 85%;
// 						}
// 					}
// 				}

// 				& > h1 {
// 					text-align: center;
// 					font-size: 35px;
// 					font-weight: bolder;
// 				}
// 				& > h2 {
// 					// text-align: center;
// 					// font-size: 35px;
// 					// font-weight: bolder;
// 					margin-top: 5px;
// 				}
// 			}
// 			.bottom {
// 				width: 80%;
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				column-gap: 50px;
// 				margin-top: 0.5rem;

// 				img {
// 					width: 100px;
// 					height: 100px;
// 				}
// 			}
// 			.bottomtext {
// 				width: 80%;
// 				display: flex;
// 				margin-top: 1rem;
// 				flex-direction: column;
// 				text-align: center;
// 				// border: 2px solid black;
// 				// height: 100%;
// 				p {
// 					font-size: 9px;
// 					font-weight: 500;
// 				}
// 			}

// 			.header {
// 				// border: 1px solid black;
// 				width: 100%;
// 				display: flex;
// 				justify-content: end;
// 				align-items: center;
// 				p {
// 					font-family: textur !important;
// 					font-size: 65px;
// 					font-weight: 100;
// 					text-align: center;
// 					left: 13%;
// 					top: 160px;
// 					margin-right: 1rem;
// 				}
// 				img {
// 					width: 150px;
// 					height: 60px;
// 				}
// 			}
// 		}
// 		// .subpage {
// 		// 	padding: 1cm;
// 		// 	border: 5px red solid;
// 		// 	height: 256mm;
// 		// 	outline: 2cm #ffeaea solid;
// 		// }
// 	}
// }

// // @page {
// // 	size: A4;
// // 	margin: 0;
// // }

// // @media print {
// // 	html,
// // 	body {
// // 		width: 100vw;
// // 		min-height: 100vh;
// // 	}
// // 	.outline {
// // 		margin: 0;
// // 		border: initial;
// // 		border-radius: initial;
// // 		width: initial;
// // 		min-height: initial;
// // 		box-shadow: initial;
// // 		background: initial;
// // 		page-break-after: always;
// // 	}
// // }

//certificate 2

.main {
	width: 100vw;
	height: 100vh;
	padding: 2rem;
	display: flex;
	justify-content: center;
	position: relative;
	overflow: scroll;

	img {
		width: 80%;
		height: 100%;
		box-shadow: 0px 0px 3px grey;
		border: 1px solid grey;
	}
	.name {
		position: absolute;
		top: 24.5%;
		font-weight: 500;
	}
	.calender {
		position: absolute;
		top: 65%;
		font-weight: 500;
		font-size: 20px;
	}
}
